import EventEmitter from "events";

export class Fabrico extends EventEmitter {
  _isSSR = typeof window === "undefined";

  constructor() {
    super();

    if (this._isSSR) return;

    // prettier-ignore
    window.addEventListener("message", (e) => {
      if (!this.frameEl || e.source !== this.frameEl.contentWindow) {
        return;
      }

      try {
        const { action } = JSON.parse(e.data);

        switch (action) {
          case "ready":
            this.emit("ready");
            break;

          case "setup":
            this.emit("setup");
            break;

          case "select-product":
            this.emit("select-product");
            break;

          case "add-template":
            this.emit("add-template");
            break;

          default:
            throw new Error(`Action "${action}" not implemented.`);
        }
      } catch (e) {
        console.error(e);
      }
    }, false);
  }

  async initialize(targetEl, token, configuration, options = {}) {
    try {
      this.frameEl = document.createElement("iframe");
      this.frameEl.src = `${process.env.GATSBY_SHIRTY_APP_URL}?token=${token}`;
      this.frameEl.width = options.width || 1024;
      this.frameEl.height = options.height || 768;
      this.frameEl.style =
        options.style || "border: none; min-width: 100vw; min-height: 100vh";
      this.frameEl.title = "Fabrico Editor";
      targetEl.appendChild(this.frameEl);

      await this._waitForEditorAction("ready");
      await this._sendToEditor("setup", configuration);
      await this._waitForEditorAction("setup");
    } catch (e) {
      console.log(e);
    }
  }

  resize(width, height) {
    this.frameEl.width = width;
    this.frameEl.height = height;
  }

  async selectProduct(product) {
    this._sendToEditor("select-product", product);
    await this._waitForEditorAction("select-product");
  }

  async addTemplate(template) {
    this._sendToEditor("add-template", template);
    await this._waitForEditorAction("add-template");
  }

  _sendToEditor(action, data) {
    this.frameEl.contentWindow.postMessage(
      JSON.stringify({ action, data }),
      "*"
    );
  }

  _waitForEditorAction(action) {
    return new Promise((r) => this.once(action, r));
  }
}
