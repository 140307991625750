import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { useLocation } from "@reach/router";
import { getLanguage } from "../utils/link";

const getPostDetailsById = (id, language, posts = []) => {
  const post = posts.find(
    (post) =>
      post.frontmatter.id === id && post.frontmatter.language === language
  );
  return post ? post.frontmatter : null;
};

/**
 * PostLink
 * @param {{ id: String, className: String, openInTab: Boolean }} props
 * @returns {React.JSX}
 */
export function PostLink({ id = "/", className = "", openInTab = false }) {
  const { pathname = "" } = useLocation();
  const { availableLanguages, defaultLanguage } = useSiteMetadata();
  const data = useStaticQuery(graphql`
    {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___createdAt] }
        filter: { frontmatter: { published: { eq: true } } }
        limit: 1000
      ) {
        nodes {
          frontmatter {
            id
            language
            title
            slug
            published
          }
        }
      }
    }
  `);

  const language = getLanguage(pathname, availableLanguages, defaultLanguage);

  const posts = data.allMdx.nodes;
  const postDetails = getPostDetailsById(id, language, posts);

  if (!postDetails) {
    if (process.env.NODE_ENV === "development") {
      console.error(
        `Invalid post ID. It should be one of: "${posts.map(
          ({ frontmatter }) => frontmatter.id
        )}"`
      );
    }
    return null;
  }

  if (openInTab) {
    return (
      <a
        href={postDetails.slug}
        className={className}
        target="_blank"
        rel="noreferrer"
      >
        {postDetails.title}
      </a>
    );
  }

  return (
    <GatsbyLink className={className} to={postDetails.slug} hrefLang={language}>
      {postDetails.title}
    </GatsbyLink>
  );
}
