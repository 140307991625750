import React from "react";
import PropTypes from "prop-types";
import { useSiteMetadata } from "../hooks";
import { Helmet } from "react-helmet";

export function SEOHead({ title, description, pathname, lang, children }) {
  const {
    defaultLanguage,
    companyName,
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image && `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
  };

  const meta = [
    { name: "title", content: seo.title },
    { name: "description", content: seo.description },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || defaultLanguage,
      }}
      title={seo.title}
      titleTemplate={`%s | ${companyName}`}
      meta={meta}
    >
      {children}
    </Helmet>
  );
}

SEOHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  lang: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};
