import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

export function WindowFrame({ className, children, title, size = "md", style, variant = "dark" }) {
  const sizeClassName = {
    sm: {
      container: "rounded-lg border-[6px] border-t-[24px]",
      dots: "-top-6 left-1 h-6 text-xs",
      dot: "h-1 w-1 h-2 w-2 mr-1",
    },
    md: {
      container: "rounded-2xl border-[6px] border-t-[28px] sm:border-[12px] sm:border-t-[48px]",
      dots: "-top-5 sm:-top-8 left-1 h-4 text-sm",
      dot: "h-2.5 w-2.5 sm:h-4 sm:w-4 mr-1",
    },
  }[size];

  const variantClassName = {
    dark: "border-gray-500 text-white",
    light: "border-slate-300 text-gray-900",
  }[variant];

  return (
    <div
      style={style}
      className={clsx(
        className,
        "relative shadow-2xl shadow-gray-500",
        sizeClassName.container,
        variantClassName,
      )}
    >
      <div className={clsx("flex absolute items-center justify-center", sizeClassName.dots)}>
        <div className={clsx("rounded-2xl bg-red-300", sizeClassName.dot)}></div>
        <div className={clsx("rounded-2xl bg-yellow-300", sizeClassName.dot)}></div>
        <div className={clsx("rounded-2xl bg-green-300", sizeClassName.dot)}></div>
        {title && <p className="ml-2 font-medium uppercase">{title}</p>}
      </div>
      <div>{children}</div>
    </div>
  );
}

WindowFrame.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["dark", "light"]),
  size: PropTypes.oneOf(["sm", "md"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};
