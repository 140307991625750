import React, { useState } from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";
import { Modal } from "./Modal";
import { WindowFrame } from "./WindowFrame";
import { EditorPlayground } from "./EditorPlayground";
import { Icon } from "./Icon";

export function EditorPreview({ title, description, btnText }) {
  const intl = useIntl();
  const [isPlaygroundModalOpen, setPlaygroundModalOpen] = useState(false);

  btnText =
    btnText || intl.formatMessage({ id: "component.editorPreview.btnText" });

  return (
    <section className="relative px-4 sm:px-6 py-10 md:py-20">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto">
        <div className="max-w-3xl mx-auto text-center pb-12">
          <h3 className="h2 mb-4">{title}</h3>
          <p className="text-xl text-gray-600">{description}</p>
        </div>
        <div>
          <div
            className="relative flex justify-center mb-8"
            data-aos="zoom-y-out"
            data-aos-delay="450"
          >
            <div className="flex flex-col justify-center">
              <WindowFrame className="bg-white" size="sm" variant="dark">
                <StaticImage
                  className="mx-auto opacity-40"
                  src="../../static/editor-md.png"
                  alt="Editor"
                />
              </WindowFrame>
            </div>
            <div className="absolute top-full transform -translate-y-1/2">
              <button
                className="btn btn-lg btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPlaygroundModalOpen(true);
                }}
                aria-controls="modal"
              >
                <Icon name="Rocket" />
                <span className="ml-3">{btnText}</span>
              </button>
            </div>
          </div>

          <Modal
            id="modal-editor-playground"
            ariaLabel="modal-headline"
            show={isPlaygroundModalOpen}
            handleClose={() => setPlaygroundModalOpen(false)}
            className="bg-white h-full w-full"
          >
            <div className="relative">
              <EditorPlayground />
              <button
                className="absolute flex items-center text-xs top-2 left-16 z-10 rounded-md font-medium group px-2 bg-red-500 text-white"
                onClick={() => setPlaygroundModalOpen(false)}
                aria-controls="modal"
              >
                <Icon className="mr-1" name="X" />
                {intl.formatMessage({ id: "close" })}
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}

EditorPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};
