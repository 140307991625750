import React from "react";
import clsx from "clsx";
import {
  TbGift,
  TbHeart,
  TbMug,
  TbShirt,
  TbShoppingBag,
  TbStar,
} from "react-icons/tb";

export function BgIcons({ className }) {
  return (
    <div className={clsx("opacity-10", className)}>
      <TbShirt className="absolute left-20 top-0 w-40 h-40 -rotate-45" />
      <TbMug className="absolute right-20 top-2/3 w-40 h-40 rotate-45" />
      <TbGift className="absolute left-0 top-1/2 w-32 h-32 rotate-45" />
      <TbHeart className="absolute right-0 top-0 w-32 h-32 -rotate-45" />
      <TbShoppingBag className="absolute left-1/4 top-1/3 w-16 h-16 rotate-45" />
      <TbStar className="absolute right-1/4 top-1/3 w-16 h-16 rotate-45" />
    </div>
  );
}
