// Encrypted using https://obfuscator.io/
// with options:
// - String Array Encoding: Base64
// - Target: Browser
//
// TODO: use https://www.npmjs.com/package/javascript-obfuscator to create this file on build process.
//
// Paste below the encrypted output:

const _0xb8d917 = _0x5b8b;
(function (_0x5ac8fe, _0x3f728a) {
  const _0x2821aa = _0x5b8b,
    _0x15a836 = _0x5ac8fe();
  while (!![]) {
    try {
      const _0x910cf0 =
        -parseInt(_0x2821aa(0x1a9)) / 0x1 +
        -parseInt(_0x2821aa(0x1a6)) / 0x2 +
        parseInt(_0x2821aa(0x1a2)) / 0x3 +
        -parseInt(_0x2821aa(0x196)) / 0x4 +
        parseInt(_0x2821aa(0x19e)) / 0x5 +
        (parseInt(_0x2821aa(0x198)) / 0x6) *
          (parseInt(_0x2821aa(0x1a3)) / 0x7) +
        (parseInt(_0x2821aa(0x19f)) / 0x8) * (parseInt(_0x2821aa(0x194)) / 0x9);
      if (_0x910cf0 === _0x3f728a) break;
      else _0x15a836["push"](_0x15a836["shift"]());
    } catch (_0x2ea2e0) {
      _0x15a836["push"](_0x15a836["shift"]());
    }
  }
})(_0x1001, 0x199a0);
function _0x5b8b(_0x6f7c90, _0xe2f5dd) {
  const _0x1001b8 = _0x1001();
  return (
    (_0x5b8b = function (_0x5b8b10, _0x258dac) {
      _0x5b8b10 = _0x5b8b10 - 0x193;
      let _0x2313b0 = _0x1001b8[_0x5b8b10];
      if (_0x5b8b["LsSSTO"] === undefined) {
        var _0x4b5d9a = function (_0xadafdd) {
          const _0x4cd79e =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=";
          let _0x2c0a1e = "",
            _0x19eca5 = "";
          for (
            let _0xe7e1d = 0x0, _0x5a87b6, _0x54b641, _0x5bd231 = 0x0;
            (_0x54b641 = _0xadafdd["charAt"](_0x5bd231++));
            ~_0x54b641 &&
            ((_0x5a87b6 =
              _0xe7e1d % 0x4 ? _0x5a87b6 * 0x40 + _0x54b641 : _0x54b641),
            _0xe7e1d++ % 0x4)
              ? (_0x2c0a1e += String["fromCharCode"](
                  0xff & (_0x5a87b6 >> ((-0x2 * _0xe7e1d) & 0x6))
                ))
              : 0x0
          ) {
            _0x54b641 = _0x4cd79e["indexOf"](_0x54b641);
          }
          for (
            let _0x3f670d = 0x0, _0x5f4e1e = _0x2c0a1e["length"];
            _0x3f670d < _0x5f4e1e;
            _0x3f670d++
          ) {
            _0x19eca5 +=
              "%" +
              ("00" + _0x2c0a1e["charCodeAt"](_0x3f670d)["toString"](0x10))[
                "slice"
              ](-0x2);
          }
          return decodeURIComponent(_0x19eca5);
        };
        (_0x5b8b["AmqWIR"] = _0x4b5d9a),
          (_0x6f7c90 = arguments),
          (_0x5b8b["LsSSTO"] = !![]);
      }
      const _0x9fa077 = _0x1001b8[0x0],
        _0x4c84e5 = _0x5b8b10 + _0x9fa077,
        _0x46f9bd = _0x6f7c90[_0x4c84e5];
      return (
        !_0x46f9bd
          ? ((_0x2313b0 = _0x5b8b["AmqWIR"](_0x2313b0)),
            (_0x6f7c90[_0x4c84e5] = _0x2313b0))
          : (_0x2313b0 = _0x46f9bd),
        _0x2313b0
      );
    }),
    _0x5b8b(_0x6f7c90, _0xe2f5dd)
  );
}
function _0x1001() {
  const _0x4500c4 = [
    "x19WB3n0",
    "nJe1mJn4zxPRt3e",
    "zMfICMLJB19HCgK",
    "ndm2otvNzuvcvKm",
    "C2vUzeXVz2LUrw1HAwW",
    "mZy1nJmYBufkzLjm",
    "l3nLBMrdB250ywn0rM9YBq",
    "ndmXmZa0Aw9rDwDg",
    "l3zLCMLMEvrVA2vU",
    "l3nLBMrbDxrOrw1HAwW",
    "y2HHCKnVzgvbDa",
    "y3jLyxrLvxnLCG",
    "CgfYC2u",
    "ody2odu1DuLAwKHl",
    "ohLIwLD2qG",
    "z2v0vxnLCKnVBMzPzW",
    "x19Nzxq",
    "oty4ndzZswzlCu0",
    "mtrIEMDyELK",
    "zNjVBunOyxjdB2rL",
    "Dg9Rzw4",
    "mtKYotyYvw9mDfPv",
    "Dw5KzwzPBMvK",
  ];
  _0x1001 = function () {
    return _0x4500c4;
  };
  return _0x1001();
}
typeof window !== _0xb8d917(0x1a7) &&
  ((window[_0xb8d917(0x193)] = {}),
  (window[_0xb8d917(0x193)][_0xb8d917(0x19c)] = async (_0xadafdd) => {
    const { data: _0x4cd79e } = await window["__post"]("/users", _0xadafdd);
    return _0x4cd79e;
  }),
  (window[_0xb8d917(0x193)][_0xb8d917(0x195)] = async (_0x2c0a1e) => {
    const _0x37273c = _0xb8d917,
      { data: _0x19eca5 } = await window[_0x37273c(0x1a8)](
        _0x37273c(0x19a),
        _0x2c0a1e
      );
    return _0x19eca5;
  }),
  (window[_0xb8d917(0x193)]["verifyToken"] = async (_0xe7e1d) => {
    const _0x370eb4 = _0xb8d917,
      { data: _0x5a87b6 } = await window[_0x370eb4(0x1a8)](
        _0x370eb4(0x199),
        null,
        { params: { token: _0xe7e1d } }
      );
    return _0x5a87b6[_0x370eb4(0x1a5)];
  }),
  (window["fabrico_api"][_0xb8d917(0x1a0)] = async (_0x54b641) => {
    const _0x2dedff = _0xb8d917,
      { data: _0x5bd231 } = await window[_0x2dedff(0x1a1)]("/config", {
        params: { token: _0x54b641 },
      }),
      _0x3f670d = _0x5bd231["replace"](/[a-z]/gi, (_0x5f4e1e) => {
        const _0x252aa9 = _0x2dedff;
        return String[_0x252aa9(0x1a4)](
          _0x5f4e1e[_0x252aa9(0x19b)](0x0) +
            (_0x5f4e1e["toLowerCase"]() <= "m" ? 0xd : -0xd)
        );
      });
    return JSON[_0x2dedff(0x19d)](atob(_0x3f670d));
  }),
  (window[_0xb8d917(0x193)]["sendContactForm"] = async (_0x4737ef) => {
    const _0xc52ce1 = _0xb8d917,
      { data: _0x2e1942 } = await window[_0xc52ce1(0x1a8)](
        _0xc52ce1(0x197),
        _0x4737ef
      );
    return _0x2e1942;
  }));
