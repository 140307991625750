import React from "react";
import clsx from "clsx";

const DEFAULT_CLASS_NAME =
  "mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";

export function Select({ id, className = "", children, ...rest }) {
  return (
    <select className={clsx(className, DEFAULT_CLASS_NAME)} {...rest}>
      {children}
    </select>
  );
}
