import React from "react";
import clsx from "clsx";

const DEFAULT_CLASS_NAME =
  "mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";

export function Input({
  id,
  className = "",
  children,
  type = "text",
  ...rest
}) {
  return (
    <input
      id={id}
      className={clsx(className, DEFAULT_CLASS_NAME)}
      type={type}
      {...rest}
    >
      {children}
    </input>
  );
}
