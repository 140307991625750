import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Logo } from "./Logo";
import { Link } from "./Link";
import { Modal } from "./Modal";
import { FormSignIn } from "./FormSignIn";
import { FormSignUp } from "./FormSignUp";
import { useIntl } from "react-intl";
import { useSiteMetadata } from "../hooks";

const SCROLL_TOP_THRESHOLD = 10;

const modalIdMap = {
  signIn: "signIn",
  signUp: "signUp",
};

export function Header({ fixed = true }) {
  const intl = useIntl();
  const siteMetadata = useSiteMetadata();

  const [top, setTop] = useState(true);
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);

  useEffect(() => {
    if (!fixed) {
      return;
    }

    const scrollHandler = () => {
      window.pageYOffset > SCROLL_TOP_THRESHOLD ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [fixed, top]);

  const closeModal = () => {
    setModalOpen(null);
  };

  return (
    <>
      <header
        className={clsx(
          "w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out",
          {
            "bg-white backdrop-blur-sm shadow-lg": !top,
            fixed: fixed,
          }
        )}
      >
        <div className="max-w-6xl mx-auto px-5 sm:px-6">
          <div className="flex items-center justify-between h-16 md:h-20">
            <div className="flex-shrink-0 w-1/3 mr-4">
              <Link to={siteMetadata.homeLink} className="block">
                <Logo large />
              </Link>
            </div>

            <nav className="flex flex-grow">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                {siteMetadata.languagesMenu.length > 0 && (
                  <li>
                    <div className="relative inline-block text-left">
                      <div>
                        <button
                          type="button"
                          className="uppercase inline-flex w-full justify-center rounded-md border-gray-300 px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                          onClick={() => setLangDropdownOpen(!langDropdownOpen)}
                          id="menu-button"
                          aria-expanded={langDropdownOpen}
                          aria-haspopup="true"
                        >
                          {siteMetadata.currentLanguage}
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden={!langDropdownOpen}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      <div
                        className={clsx(
                          "absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                          {
                            hidden: !langDropdownOpen,
                          }
                        )}
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabIndex="-1"
                      >
                        <div className="py-1" role="none">
                          {siteMetadata.languagesMenu.map((item) => (
                            <a
                              key={item.href}
                              href={item.href}
                              className="uppercase text-gray-600 block px-5 py-2 text-sm"
                              role="menuitem"
                              tabIndex="-1"
                              id="menu-item-0"
                            >
                              {item.label}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                <li className="hidden sm:block">
                  <button
                    className="btn btn-sm btn-link"
                    aria-controls="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setModalOpen(modalIdMap.signIn);
                    }}
                  >
                    <span>{intl.formatMessage({ id: "signIn" })}</span>
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-sm btn-secondary ml-3"
                    aria-controls="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setModalOpen(modalIdMap.signUp);
                    }}
                  >
                    <span>{intl.formatMessage({ id: "signUp" })}</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <Modal
        id="modal-header"
        ariaLabel="modal-headline"
        show={modalOpen !== null}
        handleClose={closeModal}
        className="w-full"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={closeModal} />

          <div className={clsx({ hidden: modalOpen !== modalIdMap.signIn })}>
            <FormSignIn />
            <p className="block text-sm text-gray-700">
              {intl.formatMessage({ id: "noAccount" })}{" "}
              <button
                onClick={() => setModalOpen(modalIdMap.signUp)}
                className="font-medium"
              >
                {intl.formatMessage({ id: "createAnAccount" })}
              </button>
            </p>
          </div>

          <div className={clsx({ hidden: modalOpen !== modalIdMap.signUp })}>
            <FormSignUp />
            <p className="block text-sm text-gray-700">
              {intl.formatMessage({ id: "alreadyHaveAnAccount" })}{" "}
              <button
                onClick={() => setModalOpen(modalIdMap.signIn)}
                className="font-medium"
              >
                {intl.formatMessage({ id: "signIn" })}
              </button>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

Header.propTypes = {
  fixed: PropTypes.bool,
};
