import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Transition } from "./Transition";
import clsx from "clsx";
import { Icon } from "./Icon";

export function Modal({ children, id, ariaLabel, show, handleClose, className }) {
  const modalContent = useRef(null);

  // close the modal on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!show || modalContent.current.contains(target)) return;
      handleClose();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [show, handleClose]);

  // close the modal if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);

    return () => document.removeEventListener("keydown", keyHandler);
  }, [handleClose]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.classList.toggle("overflow-hidden");
    }

    return () => body.classList.remove("overflow-hidden");
  }, [show]);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 z-50 bg-gray-700 bg-opacity-75 transition-opacity backdrop-blur-sm"
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center transform"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabel}
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0 scale-95"
        enterEnd="opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100 scale-100"
        leaveEnd="opacity-0 scale-95"
      >
        <div
          className={clsx("overflow-auto", className)}
          ref={modalContent}
        >
          {children}
        </div>
      </Transition>
    </>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

Modal.Close = ({ className, handleClose }) => {
  return (
    <button className={clsx("font-medium text-gray-600 hover:text-gray-900 p-2 absolute top-1.5 right-1.5", className)} onClick={handleClose}>
      <Icon name="X" />
    </button>
  );
};
