import React from "react";
import clsx from "clsx";

const DEFAULT_CLASS_NAME = "block text-sm font-medium text-gray-700";

export function Label({ id, className = "", children }) {
  return (
    <label
      htmlFor={id}
      className={clsx(className, DEFAULT_CLASS_NAME)}
    >
      {children}
    </label>
  );
}
