import React, { useState } from "react";
import { TextBlock } from "./TextBlock";
import { Modal } from "./Modal";
import { ContactForm } from "./ContactForm";
import { FormSignUp } from "./FormSignUp";
import { useIntl } from "react-intl";
import { Icon } from "./Icon";

export function GetStarted() {
  const intl = useIntl();

  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [contactFormModalOpen, setContactFormModalOpen] = useState(false);

  const openSignUpModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSignUpModalOpen(true);
  };

  const openContactFormModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContactFormModalOpen(true);
  };

  return (
    <section className="relative px-4 sm:px-6 py-10 md:py-20">
      <div className="relative max-w-6xl mx-auto text-center">
        <TextBlock
          title={intl.formatMessage({ id: "component.getStarted.title" })}
          description={intl.formatMessage({
            id: "component.getStarted.description",
          })}
        />

        <div className="flex justify-center">
          <button className="mr-2 btn btn-primary" onClick={openSignUpModal}>
            <Icon name="Rocket" className="hidden sm:block" />
            <span className="sm:ml-3">
              {intl.formatMessage({
                id: "component.getStarted.tryEditorBtnText",
              })}
            </span>
          </button>
          <button className="btn btn-secondary" onClick={openContactFormModal}>
            {intl.formatMessage({
              id: "component.getStarted.contactUsBtnText",
            })}
          </button>
        </div>
      </div>

      <Modal
        id="modal-sign-up-getStarted"
        ariaLabel="modal-headline"
        show={signUpModalOpen}
        handleClose={() => setSignUpModalOpen(false)}
        className="w-full"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={() => setSignUpModalOpen(false)} />
          <FormSignUp />
        </div>
      </Modal>

      <Modal
        id="modal-contact-form-getStarted"
        ariaLabel="modal-headline"
        show={contactFormModalOpen}
        handleClose={() => setContactFormModalOpen(false)}
        className="h-full sm:h-auto"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={() => setContactFormModalOpen(false)} />
          <div className="prose text-center mb-5">
            <h3>
              {intl.formatMessage({ id: "component.getStarted.formTitle" })}
            </h3>
            <ContactForm compact />
          </div>
        </div>
      </Modal>
    </section>
  );
}
