exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-layout-empty-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-landing-b-2-b-en-mdx": () => import("./../../../src/templates/LayoutEmpty.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/landing-b2b.en.mdx" /* webpackChunkName: "component---src-templates-layout-empty-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-landing-b-2-b-en-mdx" */),
  "component---src-templates-layout-empty-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-landing-b-2-b-es-mdx": () => import("./../../../src/templates/LayoutEmpty.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/landing-b2b.es.mdx" /* webpackChunkName: "component---src-templates-layout-empty-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-landing-b-2-b-es-mdx" */),
  "component---src-templates-layout-medium-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-contact-us-en-mdx": () => import("./../../../src/templates/LayoutMedium.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/contact-us.en.mdx" /* webpackChunkName: "component---src-templates-layout-medium-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-contact-us-en-mdx" */),
  "component---src-templates-layout-medium-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-contact-us-es-mdx": () => import("./../../../src/templates/LayoutMedium.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/contact-us.es.mdx" /* webpackChunkName: "component---src-templates-layout-medium-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-contact-us-es-mdx" */),
  "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-blog-about-fabrico-en-mdx": () => import("./../../../src/templates/LayoutWide.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/blog-about-fabrico.en.mdx" /* webpackChunkName: "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-blog-about-fabrico-en-mdx" */),
  "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-blog-about-fabrico-es-mdx": () => import("./../../../src/templates/LayoutWide.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/blog-about-fabrico.es.mdx" /* webpackChunkName: "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-blog-about-fabrico-es-mdx" */),
  "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-privacy-policy-en-mdx": () => import("./../../../src/templates/LayoutWide.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/privacy-policy.en.mdx" /* webpackChunkName: "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-privacy-policy-en-mdx" */),
  "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-privacy-policy-es-mdx": () => import("./../../../src/templates/LayoutWide.jsx?__contentFilePath=/Users/dani/code/d4n1b/shirty-landing/posts/privacy-policy.es.mdx" /* webpackChunkName: "component---src-templates-layout-wide-jsx-content-file-path-users-dani-code-d-4-n-1-b-shirty-landing-posts-privacy-policy-es-mdx" */)
}

