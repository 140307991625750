import { Config } from "./Config";
import { ContactForm } from "./ContactForm";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Banner } from "./Banner";
import { Hero } from "./Hero";
import { Features } from "./Features";
import { HowItWorks } from "./HowItWorks";

// Expose component for MDX
export const components = {
  Config,
  ContactForm,
  Header,
  Footer,
  Banner,
  Hero,
  Features,
  HowItWorks,
};

export * from "./Dropdown";
export * from "./EditorPreview";
export * from "./Banner";
export * from "./Divider";
export * from "./Hero";
export * from "./IntegrationPreview";
export * from "./EditorPlayground";
export * from "./Features";
export * from "./HowItWorks";
export * from "./GetStarted";
export * from "./Footer";
export * from "./Header";
export * from "./Icon";
export * from "./Link";
export * from "./Logo";
export * from "./Modal";
export * from "./Newsletter";
export * from "./PostLink";
export * from "./SEOHead";
export * from "./Testimonials";
export * from "./TextBlock";
export * from "./Transition";
export * from "./WindowFrame";
