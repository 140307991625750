import React, { useState } from "react";
import { Transition } from "./Transition";
import { TextBlock } from "./TextBlock";
import { Icon } from "./Icon";
import { useFeatures } from "../hooks";

export function Features() {
  const [tab, setTab] = useState(0);
  const { title, subtitle, features } = useFeatures();

  return (
    <section className="relative px-4 sm:px-6 py-10 md:py-20">
      <div
        className="absolute inset-0 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl mx-auto">
        <div className="max-w-3xl mx-auto text-center">
          <TextBlock title={title} description={subtitle} />
        </div>

        <div className="flex flex-wrap">
          <div
            className="w-full md:w-1/2 mx-auto md:mt-6"
            data-aos="fade-right"
          >
            {/* Tabs buttons */}
            <div className="mb-8 md:mb-0">
              {features.map((feature, index) => (
                <a
                  key={feature.title}
                  className={`flex justify-between items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 ${
                    tab !== index
                      ? "bg-white shadow-md border-gray-200 hover:shadow-lg"
                      : "bg-gray-200 border-transparent"
                  }`}
                  href="#features"
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(index);
                  }}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">
                      {feature.title}
                    </div>
                    <div className="hidden sm:block text-gray-600">{feature.description}</div>
                  </div>
                  {feature.icon && (
                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                      <Icon name={feature.icon} />
                    </div>
                  )}
                </a>
              ))}
            </div>
          </div>

          {/* Tabs content */}
          <div
            className="w-full md:w-1/2 mx-auto md:mt-6"
            data-aos="zoom-y-out"
          >
            <div className="relative flex flex-col text-center lg:text-right">
              {features.map((feature, index) => (
                <Transition
                  key={feature.title}
                  show={tab === index}
                  appear
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative ml-auto md:pl-5 md:max-w-sm">
                    {tab === index && (
                      <>
                        <div className="sm:hidden text-gray-600 mb-5">{feature.description}</div>
                        <video className="shadow border-4 border-gray-200" autoPlay muted loop>
                          <source src={feature.videoSrc} type="video/mp4" />
                        </video>
                      </>
                    )}
                  </div>
                </Transition>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
