import React, { useState } from "react";
import { useIntl } from "react-intl";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { EMAIL_PATTERN } from "../constants";
import { useCreateUser, useSiteMetadata } from "../hooks";
import { fieldKey, formDataToMailingData, getURL } from "../mailchimp";
import { Input } from "./form/Input";
import { Label } from "./form/Label";
import { Icon } from "./Icon";
import { PostLink } from "./PostLink";

const key = {
  title: "signUpForm.title",
  description: "signUpForm.description",
  email: "signUpForm.email.label",
  submit: "signUpForm.submit.label",
  submitSuccess: "signUpForm.submit.success",
  submitError: "signUpForm.submit.error",
  policyAgreement: "policyAgreement",
};

const defaultState = {
  [fieldKey.COMPANY_EMAIL]: "",
  [fieldKey.TERMS_AGREED]: false,
};

export function FormSignUp() {
  const intl = useIntl();
  const { mailchimp } = useSiteMetadata();

  const [formData, setFormData] = useState(defaultState);

  const { isLoading, isSuccess, isError, mutateAsync } = useCreateUser();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = async (event, subscribe) => {
    event.preventDefault();

    await mutateAsync(formData);
    subscribe(formDataToMailingData(formData));
    setFormData(defaultState);
  };

  return (
    <MailchimpSubscribe
      url={getURL(mailchimp)}
      render={({ subscribe }) => (
        <div className="prose">
          <div className="mb-4 text-center">
            <h3>{intl.formatMessage({ id: key.title })}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: key.description }),
              }}
            ></p>
          </div>
          <form
            className="rounded"
            onSubmit={(event) => handleSubmit(event, subscribe)}
          >
            <div className="mb-4">
              <Label id={fieldKey.COMPANY_EMAIL}>
                {intl.formatMessage({ id: key.email })}
              </Label>
              <Input
                id={fieldKey.COMPANY_EMAIL}
                name={fieldKey.COMPANY_EMAIL}
                onChange={handleInputChange}
                value={formData[fieldKey.COMPANY_EMAIL]}
                type="email"
                pattern={EMAIL_PATTERN}
                required
              />
            </div>

            <div className="flex items-center mb-4 mt-6">
              <input
                id={fieldKey.TERMS_AGREED}
                name={fieldKey.TERMS_AGREED}
                onChange={handleCheckboxChange}
                checked={formData[fieldKey.TERMS_AGREED]}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                required
              />
              <Label id={fieldKey.TERMS_AGREED} className="ml-2">
                {intl.formatMessage(
                  { id: key.policyAgreement },
                  {
                    link: <PostLink id="privacy-policy" openInTab />,
                  }
                )}
              </Label>
            </div>

            <div className="text-center mb-6">
              <button
                className="btn btn-primary w-full loading"
                type="submit"
              >
                {isLoading && (
                  <Icon
                    name="Spinner"
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  />
                )}
                {intl.formatMessage({ id: key.submit })}
              </button>
            </div>

            <div className="text-center mb-6">
              {isSuccess && (
                <div className="px-3 py-2 bg-green-50">
                  {intl.formatMessage({ id: key.submitSuccess })}
                </div>
              )}
              {isError && (
                <div className="px-3 py-2 bg-red-50">
                  {intl.formatMessage({ id: key.submitError })}
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    />
  );
}
