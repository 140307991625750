import React from "react";
import { useIntl } from "react-intl";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { Logo } from "./Logo";
import { PostLink } from "./PostLink";

const currentYear = new Date().getFullYear();

export function Footer() {
  const intl = useIntl();
  const siteMetadata = useSiteMetadata();

  const footer = siteMetadata.footer || [];
  const socials = siteMetadata.socials || [];
  const { companyName } = siteMetadata;

  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3">
            <div className="mb-2">
              <Link to={siteMetadata.homeLink} className="inline-block" aria-label={companyName}>
                <Logo large />
              </Link>
            </div>
          </div>

          {footer.map((item) => (
            <div
              key={item.key}
              className="sm:col-span-6 md:col-span-3 lg:col-span-2"
            >
              {item.key && (
                <p className="text-gray-800 font-bold mb-2">
                  {intl.formatMessage({ id: item.key })}
                </p>
              )}
              <ul className="text-sm">
                {item.children.map((child) => (
                  <li key={child.postId} className="mb-2">
                    <PostLink
                      id={child.postId}
                      className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            {socials.map((item, index) => (
              <li key={item.link} className={index > 0 ? `ml-3` : ``}>
                <a
                  href={item.link}
                  className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label={item.icon}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="w-8 h-8 fill-current flex justify-center items-center">
                    <Icon name={item.icon} />
                  </span>
                </a>
              </li>
            ))}
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">
            © {currentYear}{" "}
            <Link to={siteMetadata.homeLink} className="text-primary-600 hover:underline" aria-label={companyName}>
              {companyName}
            </Link>
            . {intl.formatMessage({ id: "allRightsReserved" })}
          </div>
        </div>
      </div>
    </footer>
  );
}
