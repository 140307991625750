const { QueryClient, QueryClientProvider } = require("react-query");
const React = require("react");
const { IntlProvider } = require("react-intl");
const { MDXProvider } = require("@mdx-js/react");
const { components } = require("../components");

const queryClient = new QueryClient();

exports.PageWrapper = ({ element, props }) => {
  let { messages, language } = props.pageContext;

  // Refresh translations on update
  if (process.env.NODE_ENV === "development") {
    const gatsbyConfig = require("../../gatsby-config");
    const { interpolateMessages } = require("../utils/i18n");

    messages = interpolateMessages(
      require("../../i18n-translations.json"),
      language,
      gatsbyConfig.siteMetadata
    );
  }

  return (
    <MDXProvider components={components} disableParentContext={false}>
      <IntlProvider locale={language} messages={messages}>
        <QueryClientProvider client={queryClient}>
          {element}
        </QueryClientProvider>
      </IntlProvider>
    </MDXProvider>
  );
};
