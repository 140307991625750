import React, { useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { EMAIL_PATTERN } from "../constants";
import countries from "../countries.json";
import { useSendContactForm, useSiteMetadata } from "../hooks";
import { fieldKey, formDataToMailingData, getURL } from "../mailchimp";
import { PostLink } from "./PostLink";
import { Icon } from "./Icon";
import { Input, Label, Select } from "./form";
import options from "./contact-form-options.json";

const key = {
  firstName: "contactForm.firstName.label",
  lastName: "contactForm.lastName.label",
  jobTitle: "contactForm.jobTitle.label",
  email: "contactForm.email.label",
  companyName: "contactForm.companyName.label",
  companyLocation: "contactForm.companyLocation.label",
  companySize: "contactForm.companySize.label",
  intendedUse: "contactForm.intendedUse.label",
  message: "contactForm.message.label",
  submit: "contactForm.submit.label",
  submitSuccess: "contactForm.submit.success",
  submitError: "contactForm.submit.error",
  policyAgreement: "policyAgreement",
  select: "select",
};

const defaultState = Object.values(fieldKey).reduce(
  (state, key) => ({ ...state, [key]: "" }),
  {}
);

export function ContactForm({ className, compact = false }) {
  const { mailchimp } = useSiteMetadata();
  const { isLoading, isSuccess, isError, mutateAsync } = useSendContactForm();

  const handleSubmit = async (event, formData, subscribe) => {
    event.preventDefault();

    await mutateAsync(formData);
    subscribe(formDataToMailingData(formData));
  };

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={getURL(mailchimp)}
        render={({ subscribe }) => (
          <Form
            compact={compact}
            className={className}
            loading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            onSubmit={(event, formData) =>
              handleSubmit(event, formData, subscribe)
            }
          />
        )}
      />
    </div>
  );
}

function Form({ compact, className, loading, isSuccess, isError, onSubmit }) {
  const intl = useIntl();
  const [formData, setFormData] = useState(defaultState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };

  return (
    <form
      action="#"
      onSubmit={(event) => onSubmit(event, formData)}
      className={clsx("text-left", className)}
    >
      <div className="flex flex-wrap -mx-2.5">
        <div className="w-full sm:w-1/2 p-2.5">
          <Label id={fieldKey.FIRST_NAME}>
            {intl.formatMessage({ id: key.firstName })}
          </Label>
          <Input
            id={fieldKey.FIRST_NAME}
            name={fieldKey.FIRST_NAME}
            onChange={handleInputChange}
            value={formData[fieldKey.FIRST_NAME]}
            required
          />
        </div>

        <div className={clsx("w-full sm:w-1/2 p-2.5", { hidden: compact })}>
          <Label id={fieldKey.LAST_NAME}>
            {intl.formatMessage({ id: key.lastName })}
          </Label>
          <Input
            id={fieldKey.LAST_NAME}
            name={fieldKey.LAST_NAME}
            onChange={handleInputChange}
            value={formData[fieldKey.LAST_NAME]}
            required={!compact}
          />
        </div>

        <div className="w-full sm:w-1/2 p-2.5">
          <Label id={fieldKey.JOB_TITLE}>
            {intl.formatMessage({ id: key.jobTitle })}
          </Label>
          <Select
            id={fieldKey.JOB_TITLE}
            name={fieldKey.JOB_TITLE}
            onChange={handleInputChange}
            value={formData[fieldKey.JOB_TITLE]}
            required
          >
            <option value="" default disabled>
              {intl.formatMessage({ id: key.select })}
            </option>
            {options.jobTitles.map((option) => (
              <option value={option.value} key={option.label}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </div>

        <div className="w-full sm:w-1/2 p-2.5">
          <Label id={fieldKey.COMPANY_EMAIL}>
            {intl.formatMessage({ id: key.email })}
          </Label>
          <Input
            id={fieldKey.COMPANY_EMAIL}
            name={fieldKey.COMPANY_EMAIL}
            onChange={handleInputChange}
            value={formData[fieldKey.COMPANY_EMAIL]}
            type="email"
            pattern={EMAIL_PATTERN}
            required
          />
        </div>

        <div className="w-full sm:w-1/2 p-2.5">
          <Label id={fieldKey.COMPANY_NAME}>
            {intl.formatMessage({ id: key.companyName })}
          </Label>
          <Input
            id={fieldKey.COMPANY_NAME}
            name={fieldKey.COMPANY_NAME}
            onChange={handleInputChange}
            value={formData[fieldKey.COMPANY_NAME]}
            required
          />
        </div>

        <div className={clsx("w-full sm:w-1/2 p-2.5", { hidden: compact })}>
          <Label id={fieldKey.COMPANY_LOCATION}>
            {intl.formatMessage({ id: key.companyLocation })}
          </Label>
          <Select
            id={fieldKey.COMPANY_LOCATION}
            name={fieldKey.COMPANY_LOCATION}
            onChange={handleInputChange}
            value={formData[fieldKey.COMPANY_LOCATION]}
            required={!compact}
          >
            <option value="" default disabled>
              {intl.formatMessage({ id: key.select })}
            </option>
            {countries.map((country) => (
              <option value={country.name} key={country.name}>
                {country.name}
              </option>
            ))}
          </Select>
        </div>

        <div className={clsx("w-full sm:w-1/2 p-2.5", { hidden: compact })}>
          <Label id={fieldKey.COMPANY_SIZE}>
            {intl.formatMessage({ id: key.companySize })}
          </Label>
          <Select
            id={fieldKey.COMPANY_SIZE}
            name={fieldKey.COMPANY_SIZE}
            onChange={handleInputChange}
            value={formData[fieldKey.COMPANY_SIZE]}
            required={!compact}
          >
            <option value="" default disabled>
              {intl.formatMessage({ id: key.select })}
            </option>
            {options.companySize.map((option) => (
              <option value={option.value} key={option.label}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </div>

        <div className={clsx("w-full sm:w-1/2 p-2.5", { hidden: compact })}>
          <Label id={fieldKey.INTENDED_USE}>
            {intl.formatMessage({ id: key.intendedUse })}
          </Label>
          <Select
            id={fieldKey.INTENDED_USE}
            name={fieldKey.INTENDED_USE}
            onChange={handleInputChange}
            value={formData[fieldKey.INTENDED_USE]}
            required={!compact}
          >
            <option value="" default disabled>
              {intl.formatMessage({ id: key.select })}
            </option>
            {options.intendedUse.map((option) => (
              <option value={option.value} key={option.label}>
                {intl.formatMessage({ id: option.label })}
              </option>
            ))}
          </Select>
        </div>

        <div className="w-full p-2.5">
          <Label id={fieldKey.MESSAGE}>
            {intl.formatMessage({ id: key.message })}
          </Label>
          <textarea
            id={fieldKey.MESSAGE}
            name={fieldKey.MESSAGE}
            onChange={handleInputChange}
            value={formData[fieldKey.MESSAGE]}
            className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          ></textarea>
        </div>

        <div className="w-full p-2.5">
          {isSuccess && (
            <div className="px-3 py-2 bg-green-50">
              {intl.formatMessage({ id: key.submitSuccess })}
            </div>
          )}
          {isError && (
            <div className="px-3 py-2 bg-red-50">
              {intl.formatMessage({ id: key.submitError })}
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 flex flex-col items-center justify-between bg-gray-50 px-4 py-3 sm:flex-row">
        <div className="flex items-center">
          <input
            id={fieldKey.TERMS_AGREED}
            name={fieldKey.TERMS_AGREED}
            onChange={handleCheckboxChange}
            checked={formData[fieldKey.TERMS_AGREED]}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            required
          />
          <Label id={fieldKey.TERMS_AGREED} className="p-2">
            {intl.formatMessage(
              { id: key.policyAgreement },
              {
                link: <PostLink id="privacy-policy" openInTab />,
              }
            )}
          </Label>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading || isSuccess}
        >
          {loading && (
            <Icon
              name="Spinner"
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            />
          )}
          {intl.formatMessage({ id: key.submit })}
        </button>
      </div>
    </form>
  );
}
