import axios from "axios";

const http = axios.create({
  baseURL: process.env.GATSBY_SHIRTY_API_ENDPOINT,
});

if (typeof window !== "undefined") {
  window.__post = http.post;
  window.__get = http.get;
}
