import React from "react";
import clsx from "clsx";
import logoSvg from "../../static/logo.svg";
import logoSvgLarge from "../../static/logo_large.svg";

export function Logo({ className, large }) {
  if (large) {
    return (
      <img className={clsx("logo", className)} src={logoSvgLarge} alt="logo" width="150" />
    );
  }

  return <img className={clsx("logo", className)} src={logoSvg} alt="logo" />;
}
