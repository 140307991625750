export const getPathElements = (pathname) =>
  pathname.split("/").filter(Boolean);

export const getLanguage = (pathname, languages = [], defaultLanguage = "") => {
  const pathElements = getPathElements(pathname);
  for (let element of pathElements) {
    for (let language of languages) {
      if (element === language) {
        return language;
      }
    }
  }
  return defaultLanguage;
};

export const getTranslatedPath = (pathname, to) => {
  const currLanguage = getLanguage(pathname);
  const destLanguage = getLanguage(to);
  const destElements = getPathElements(to).filter(
    (part) => part !== destLanguage
  );

  let outputPath = to;

  if (currLanguage !== destLanguage && destElements.length >= 1) {
    outputPath = "/" + currLanguage + to;
  }

  const hasTrailingSlash = outputPath.endsWith("/");
  if (!hasTrailingSlash) {
    outputPath += "/";
  }

  return outputPath;
};
