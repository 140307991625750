import { useIntl } from "react-intl";
import FeatureProductsVideo from '../../static/feature-products.mp4';
import FeaturePatternVideo from '../../static/feature-patterns.mp4';
import FeatureEffectsVideo from '../../static/feature-effects.mp4';
import FeatureTemplatesVideo from '../../static/feature-templates.mp4';

export const useFeatures = () => {
  const intl = useIntl();

  return {
    title: intl.formatMessage({ id: "features.title" }),
    subtitle: intl.formatMessage({ id: "features.subtitle" }),
    features: [
      {
        title: intl.formatMessage({ id: "features.patterns.title" }),
        description: intl.formatMessage({
          id: "features.patterns.description",
        }),
        icon: intl.formatMessage({ id: "features.patterns.icon" }),
        videoSrc: FeaturePatternVideo,
      },
      {
        title: intl.formatMessage({ id: "features.effects.title" }),
        description: intl.formatMessage({ id: "features.effects.description" }),
        icon: intl.formatMessage({ id: "features.effects.icon" }),
        videoSrc: FeatureEffectsVideo,
      },
      {
        title: intl.formatMessage({ id: "features.templates.title" }),
        description: intl.formatMessage({
          id: "features.templates.description",
        }),
        icon: intl.formatMessage({ id: "features.templates.icon" }),
        videoSrc: FeatureTemplatesVideo,
      },
      {
        title: intl.formatMessage({ id: "features.products.title" }),
        description: intl.formatMessage({
          id: "features.products.description",
        }),
        icon: intl.formatMessage({ id: "features.products.icon" }),
        videoSrc: FeatureProductsVideo,
      },
    ],
  };
};
