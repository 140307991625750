import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";
import { WindowFrame } from "./WindowFrame";
import { Bg } from "./Bg";

export function IntegrationPreview({ children }) {
  const intl = useIntl();

  return (
    <section className="relative">
      <Bg.Animated
        className="absolute h-auto top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
        style={{ width: "120%" }}
      />
      <div className="max-w-6xl mx-auto">
        <div className="relative flex justify-center mb-8">
          <div className="flex flex-col justify-center">
            <WindowFrame
              size="sm"
              variant="light"
              title={intl.formatMessage({
                id: "component.integrationPreview.websiteTitle",
              })}
              className="flex bg-white m-auto w-11/12 sm:w-4/5"
              style={{ minHeight: "320px" }}
            >
              {/* Header skeleton */}
              <div className="flex justify-between px-6 sm:px-12 py-3 bg-slate-100">
                <div className="w-10 px-1 py-2 bg-slate-200 rounded-sm"></div>
                <div className="flex">
                  <div className="ml-1 w-4 px-1 py-2 bg-slate-200 rounded-sm"></div>
                  <div className="ml-1 w-4 px-1 py-2 bg-slate-200 rounded-sm"></div>
                  <div className="ml-1 w-4 px-1 py-2 bg-slate-200 rounded-sm"></div>
                </div>
              </div>
              <div className="px-6 sm:px-12 py-4 sm:py-12">
                <p className="bg-primary-400 inline-block mb-1 px-2 rounded-sm text-xs font-medium text-white">
                  {intl.formatMessage({ id: "component.integrationPreview.tag" })}
                </p>
                <div className="relative p-1 bg-sky-100 border border-sky-500 shadow-2xl shadow-gray-400">
                  <StaticImage
                    className="mx-auto"
                    src="../../static/editor-md.png"
                    alt="Editor"
                  />
                  <div className="absolute rounded-2xl h-2 w-2 -top-1 -left-1 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 -top-1 left-1/2 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 -top-1 -right-2 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 top-1/2 -right-2 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 -bottom-1 -right-2 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 -bottom-1 left-1/2 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 -bottom-1 -left-1 mr-1 bg-white border border-sky-500"></div>
                  <div className="absolute rounded-2xl h-2 w-2 bottom-1/2 -left-1 mr-1 bg-white border border-sky-500"></div>
                </div>
              </div>
              {/* Footer skeleton */}
              <div className="flex justify-between px-6 sm:px-12 py-6 bg-slate-100"></div>
            </WindowFrame>
          </div>
          {children}
        </div>
      </div>
    </section>
  );
}
