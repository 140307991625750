import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "../Modal";
import { ContactForm } from "../ContactForm";
import { TbGift, TbHeart, TbMug, TbShirt } from "react-icons/tb";

export function BannerIntegration() {
  const intl = useIntl();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="relative text-center bg-gray-900 text-primary-100 p-5 overflow-hidden">
        <div className="text-gray-700 sm:text-primary-100">
          <TbShirt className="absolute left-0 top-1/2 w-8 h-8 rotate-45" />
          <TbHeart className="absolute hidden md:block -left-2 sm:left-20 -bottom-3 sm:top-1 w-10 h-10 rotate-12" />
          <TbMug className="absolute hidden md:block right-20 top-1 w-10 h-10 -rotate-12" />
          <TbGift className="absolute -right-1 top-1/2 w-6 h-6 rotate-45" />
        </div>

        <p className="relative z-10">
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "banner.integration.text" }),
            }}
          />{" "}
          <a
            href="/"
            className="text-white"
            aria-controls="modal"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalOpen(true);
            }}
          >
            <b>{intl.formatMessage({ id: "banner.integration.cta" })}</b>
          </a>
        </p>
      </div>
      <Modal
        id="modal-banner-integration"
        ariaLabel="modal-headline"
        show={isModalOpen}
        handleClose={() => setModalOpen(false)}
        className="h-full sm:h-auto"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={() => setModalOpen(false)} />
          <div className="prose text-center mb-5">
            <h3>
              {intl.formatMessage({ id: "banner.integration.formTitle" })}
            </h3>
            <ContactForm compact />
          </div>
        </div>
      </Modal>
    </>
  );
}
