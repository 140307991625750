import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";
import { getLanguage, getTranslatedPath } from "../utils/link";

export function Link({ children = [], className = "", to = "/" }) {
  const { pathname = "" } = useLocation();

  return (
    <GatsbyLink
      className={className}
      to={getTranslatedPath(pathname, to)}
      hrefLang={getLanguage(pathname)}
    >
      {children}
    </GatsbyLink>
  );
}
