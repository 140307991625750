import React from "react";
import {
  FaCode,
  FaLayerGroup,
  FaRegImage,
  FaTshirt,
  FaChessBoard,
  FaFileImport,
  FaMagic,
  FaTwitter,
  FaGithub,
  FaInstagram,
  FaFacebookF,
  FaCircleNotch,
  FaTimes,
  FaPlay,
  FaRocket,
  FaLinkedin,
} from "react-icons/fa";
import PropTypes from "prop-types";

export function Icon({ name, ...iconProps }) {
  const Component = {
    Image: FaRegImage,
    Tshirt: FaTshirt,
    Template: FaLayerGroup,
    Pattern: FaChessBoard,
    Export: FaFileImport,
    Code: FaCode,
    Effects: FaMagic,
    Twitter: FaTwitter,
    Facebook: FaFacebookF,
    Github: FaGithub,
    Instagram: FaInstagram,
    LinkedIn: FaLinkedin,
    Spinner: FaCircleNotch,
    X: FaTimes,
    Play: FaPlay,
    Rocket: FaRocket,
  }[name];

  if (!Component) {
    return null;
  }

  return <Component {...iconProps} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};
