export const fieldKey = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  JOB_TITLE: "jobTitle",
  COMPANY_EMAIL: "email",
  COMPANY_NAME: "companyName",
  COMPANY_LOCATION: "companyLocation",
  COMPANY_SIZE: "companySize",
  INTENDED_USE: "intendedUse",
  MESSAGE: "message",
  TERMS_AGREED: "termsAgreed",
};

export const fieldMailingKeyMap = {
  [fieldKey.FIRST_NAME]: "MERGE1",
  [fieldKey.LAST_NAME]: "MERGE2",
  [fieldKey.JOB_TITLE]: "MERGE5",
  [fieldKey.COMPANY_EMAIL]: "MERGE0",
  [fieldKey.COMPANY_NAME]: "MERGE3",
  [fieldKey.COMPANY_LOCATION]: "MERGE4",
  [fieldKey.COMPANY_SIZE]: "MERGE6",
  [fieldKey.INTENDED_USE]: "MERGE7",
  [fieldKey.TERMS_AGREED]: "MERGE9",
};

export function formDataToMailingData(formData) {
  return Object.keys(formData).reduce((mailingData, key) => {
    if (fieldMailingKeyMap[key] !== undefined) {
      mailingData[fieldMailingKeyMap[key]] = formData[key];
    }
    return mailingData;
  }, {});
}

export function getURL({ u, id }) {
  return `https://hotmail.us21.list-manage.com/subscribe/post?u=${u}&id=${id}`;
}
