import { useIntl } from "react-intl";

export const useHowItWorks = () => {
  const intl = useIntl();

  return {
    title: intl.formatMessage({ id: "howItWorks.title" }),
    subtitle: intl.formatMessage({ id: "howItWorks.subtitle" }),
    features: [
      {
        title: intl.formatMessage({ id: "howItWorks.sdk.title" }),
        description: intl.formatMessage({
          id: "howItWorks.sdk.description",
        }),
        icon: intl.formatMessage({ id: "howItWorks.sdk.icon" }),
      },
      {
        title: intl.formatMessage({ id: "howItWorks.products.title" }),
        description: intl.formatMessage({
          id: "howItWorks.products.description",
        }),
        icon: intl.formatMessage({ id: "howItWorks.products.icon" }),
      },
      {
        title: intl.formatMessage({ id: "howItWorks.ui.title" }),
        description: intl.formatMessage({
          id: "howItWorks.ui.description",
        }),
        icon: intl.formatMessage({ id: "howItWorks.ui.icon" }),
      },
      {
        title: intl.formatMessage({ id: "howItWorks.export.title" }),
        description: intl.formatMessage({
          id: "howItWorks.export.description",
        }),
        icon: intl.formatMessage({ id: "howItWorks.export.icon" }),
      },
    ],
  };
};
