import React, { useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { useSiteMetadata } from "../../hooks";
import { ContactForm } from "../ContactForm";
import { EditorPlayground } from "../EditorPlayground";
import { Icon } from "../Icon";
import { IntegrationPreview } from "../IntegrationPreview";
import { Modal } from "../Modal";

export function HeroB2B({ className }) {
  const intl = useIntl();

  const siteMetadata = useSiteMetadata();
  const socials = siteMetadata.socials || [];

  const [isPlaygroundModalOpen, setPlaygroundModalOpen] = useState(false);
  const [contactFormModalOpen, setContactFormModalOpen] = useState(false);

  const [firstLine, secondLine] = intl
    .formatMessage({ id: "component.heroIntegration.title" })
    .split(",");

  return (
    <>
      <div
        className={clsx(
          "relative max-w-6xl mx-auto flex flex-wrap",
          className
        )}
      >
        <div className="z-10 w-full sm:w-5/12 md:w-1/2 pb-10 md:pb-0">
          <h1 className="font-extrabold mb-4 mt-1 text-3xl md:text-5xl lg:text-6xl leading-[1.1em] lg:leading-[1em]">
            <span>
              {firstLine}
              {secondLine && ","}
            </span>
            {secondLine && (
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-primary-500 to-secondary-400">
                {secondLine}
              </span>
            )}
          </h1>
          <p
            className="text-xl md:text-2xl md:font-medium text-gray-600 mb-4"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "component.heroIntegration.description",
              }),
            }}
          />
          <ul className="flex mb-4">
            {socials.map((item) => (
              <li key={item.link} className="mr-3">
                <a
                  href={item.link}
                  className="flex justify-center items-center bg-gray-400 hover:bg-gray-900 text-white hover:text-white-100 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label={item.icon}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="w-8 h-8 fill-current flex justify-center items-center">
                    <Icon name={item.icon} />
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full sm:w-7/12 md:w-1/2">
          <IntegrationPreview>
            <div className="absolute top-full flex transform -translate-y-1/2">
              <button
                className="mr-2 btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPlaygroundModalOpen(true);
                }}
                aria-controls="modal"
              >
                <Icon name="Rocket" className="hidden sm:block" />
                <span className="sm:ml-3">
                  {intl.formatMessage({
                    id: "component.heroIntegration.tryEditorBtnText",
                  })}
                </span>
              </button>
              <button
                className="btn btn-secondary"
                aria-controls="modal"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setContactFormModalOpen(true);
                }}
              >
                {intl.formatMessage({
                  id: "component.heroIntegration.contactUsBtnText",
                })}
              </button>
            </div>
          </IntegrationPreview>
        </div>
      </div>

      <Modal
        id="modal-sign-up-homepage"
        ariaLabel="modal-headline"
        show={contactFormModalOpen}
        handleClose={() => setContactFormModalOpen(false)}
        className="h-full sm:h-auto"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={() => setContactFormModalOpen(false)} />
          <div className="prose text-center mb-5">
            <h3>
              {intl.formatMessage({ id: "banner.integration.formTitle" })}
            </h3>
            <ContactForm short />
          </div>
        </div>
      </Modal>

      <Modal
        id="modal-editor-playground"
        ariaLabel="modal-headline"
        show={isPlaygroundModalOpen}
        handleClose={() => setPlaygroundModalOpen(false)}
        className="bg-white h-full w-full"
      >
        <div className="relative">
          <EditorPlayground />
          <button
            className="absolute flex items-center text-xs top-2 left-16 z-10 rounded-md font-medium group px-2 bg-red-500 text-white"
            onClick={() => setPlaygroundModalOpen(false)}
            aria-controls="modal"
          >
            <Icon className="mr-1" name="X" />
            {intl.formatMessage({ id: "close" })}
          </button>
        </div>
      </Modal>
    </>
  );
}
