import React, { useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Typed from "react-typed";
import { Bg } from "../Bg";

export function HeroStandard({
  title,
  description = null,
  className,
  actions = null,
  showTypingEffect,
}) {
  const renderTitle = useCallback(
    (title) => {
      const isMultiline = title.includes("\n");
      if (isMultiline) {
        const [firstLine, ...restLines] = title.split("\n");
        const secondLine = restLines.join(" ");

        title = (
          <>
            {firstLine}
            <span className="block mt-1 text-3xl md:text-5xl lg:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-primary-500 to-secondary-400">
              {showTypingEffect && secondLine.includes(",") ? (
                <Typed
                  strings={secondLine.split(",")}
                  typeSpeed={100}
                  backSpeed={100}
                  backDelay={1000}
                  loop
                />
              ) : (
                secondLine
              )}
            </span>
          </>
        );
      }
      return title;
    },
    [showTypingEffect]
  );

  return (
    <section
      className={clsx("relative max-w-6xl mx-auto text-center", className)}
    >
      <Bg.Icons className="text-gray-500" />

      <div className="relative z-10">
        <h1
          className="mb-4 text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter"
          data-aos="zoom-y-out"
        >
          {renderTitle(title)}
        </h1>
        <div className="max-w-3xl mx-auto">
          <p
            className="text-xl md:text-xl md:font-medium text-gray-600 mb-8"
            data-aos="zoom-y-out"
            data-aos-delay="150"
          >
            {description}
          </p>
          {actions && (
            <div
              className="mx-auto sm:flex sm:justify-center"
              data-aos="zoom-y-out"
              data-aos-delay="300"
            >
              {actions}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

HeroStandard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  showTypingEffect: PropTypes.bool,
};
