import React from "react";
import { Icon } from "./Icon";
import { useHowItWorks } from "../hooks";
import { Bg } from "./Bg";

export function HowItWorks() {
  const { title, subtitle, features } = useHowItWorks();

  return (
    <section className="relative px-4 sm:px-6 py-10 md:py-20">
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gradient-to-r from-gray-200 to-gray-300 pointer-events-none overflow-hidden"
        aria-hidden="true"
      >
        <Bg.Icons className="z-10 text-gray-500" />
      </div>

      <div className="relative max-w-6xl mx-auto">
        <div className="max-w-3xl mx-auto text-center pb-12">
          <h3 className="h2 mb-4">{title}</h3>
          <p className="text-xl text-gray-600">{subtitle}</p>
        </div>

        <div className="mx-auto grid gap-6 md:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="relative h-full flex flex-col items-center p-6 bg-white rounded shadow-xl border"
            >
              <span className="bg-primary-600 p-4 rounded-full">
                <Icon className="text-white w-6 h-6" name={feature.icon} />
              </span>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                {feature.title}
              </h4>
              <p className="text-gray-600 text-center">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
