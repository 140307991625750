import { useMutation, useQuery } from "react-query";
import "../api";

if (process.env.NODE_ENV === "production") {
  require("../api-global.encrypted");
} else {
  require("../api-global");
}

export const useCreateUser = () => {
  return useMutation((user) => window.fabrico_api.createUser(user), {
    retry: false,
  });
};

export const useSendLoginEmail = () => {
  return useMutation((user) => window.fabrico_api.sendLoginEmail(user), {
    retry: false,
  });
};

export const useVerifyTokenAndGetConfig = (token) => {
  try {
    return useQuery(
      "VERIFY_TOKEN",
      async () => {
        await window.fabrico_api.verifyToken(token);
        return window.fabrico_api.getUserConfig(token);
      },
      {
        retry: false,
        useErrorBoundary: true,
      }
    );
  } catch (e) {
    return {
      isError: true,
      isLoading: false,
      error: e.response?.data?.error,
    };
  }
};
