import React, { useState } from "react";
import { useIntl } from "react-intl";
import { EMAIL_PATTERN } from "../constants";
import { useSendLoginEmail } from "../hooks";
import { fieldKey } from "../mailchimp";
import { Input } from "./form/Input";
import { Label } from "./form/Label";
import { Icon } from "./Icon";

const key = {
  title: "signInForm.title",
  description: "signInForm.description",
  email: "signInForm.email.label",
  submit: "signInForm.submit.label",
  submitSuccess: "signInForm.submit.success",
  submitError: "signInForm.submit.error",
};

const defaultState = {
  [fieldKey.COMPANY_EMAIL]: "",
};

export function FormSignIn() {
  const intl = useIntl();

  const [formData, setFormData] = useState(defaultState);

  const { isLoading, isSuccess, isError, mutateAsync } = useSendLoginEmail();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    await mutateAsync(formData);
    setFormData(defaultState);
  };

  return (
    <div className="prose">
      <div className="mb-4 text-center">
        <h3>{intl.formatMessage({ id: key.title })}</h3>
        <p>{intl.formatMessage({ id: key.description })}</p>
      </div>
      <form className="rounded" onSubmit={handleSubmit}>
        <div className="mb-4">
          <Label id={fieldKey.COMPANY_EMAIL}>
            {intl.formatMessage({ id: key.email })}
          </Label>
          <Input
            id={fieldKey.COMPANY_EMAIL}
            name={fieldKey.COMPANY_EMAIL}
            onChange={handleInputChange}
            value={formData[fieldKey.COMPANY_EMAIL]}
            type="email"
            pattern={EMAIL_PATTERN}
            required
          />
        </div>

        <div className="text-center mb-6">
          <button className="btn btn-primary w-full" type="submit">
            {isLoading && (
              <Icon
                name="Spinner"
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              />
            )}
            {intl.formatMessage({ id: key.submit })}
          </button>
        </div>

        <div className="text-center mb-6">
          {isSuccess && (
            <div className="px-3 py-2 bg-green-50">
              {intl.formatMessage({ id: key.submitSuccess })}
            </div>
          )}
          {isError && (
            <div className="px-3 py-2 bg-red-50">
              {intl.formatMessage({ id: key.submitError })}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
