import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import { getLanguage } from "../utils/link";
import { useMemo } from "react";

/**
 *
 * @returns {import("../types").SiteMetadata & {
 *  languagesMenu: { href: String, label: String }[]
 *  currentLanguage: String,
 *  homeLink: String,
 * }}
 */
export const useSiteMetadata = () => {
  const location = useLocation();

  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            siteUrl
            companyName
            contactEmail
            defaultLanguage
            availableLanguages
            defaultTemplateFile
            footer {
              key
              children {
                postId
              }
            }
            socials {
              icon
              link
            }
            colors {
              primary
              secondary
            }
            mailchimp {
              id
              u
            }
          }
        }
      }
    `
  );

  const extendedSiteMetadata = useMemo(() => {
    const currentLanguage = getLanguage(
      location.pathname,
      site.siteMetadata.availableLanguages,
      site.siteMetadata.defaultLanguage
    );

    const getLanguageLink = (language) =>
      language === site.siteMetadata.defaultLanguage ? "/" : `/${language}`;

    const languagesMenu = site.siteMetadata.availableLanguages
      .filter((language) => language !== currentLanguage)
      .map((language) => ({
        href: getLanguageLink(language),
        label: language,
      }));

    return {
      ...site.siteMetadata,
      currentLanguage,
      languagesMenu,
      homeLink: getLanguageLink(currentLanguage),
    };
  }, [location.pathname, site]);

  return extendedSiteMetadata;
};
