import React from "react";
import PropTypes from "prop-types";

export function TextBlock({ title, description }) {
  return (
    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
      <p className="h2 mb-4">{title}</p>
      <p
        className="text-xl text-gray-600"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  );
}

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
