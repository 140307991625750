import _ from "lodash";
import React, { useRef, useMemo, useEffect, useState } from "react";
import { Fabrico } from "../sdk/sdk";
import { useVerifyTokenAndGetConfig } from "../hooks";

export const EditorPlayground = () => {
  const editor = useMemo(() => new Fabrico(), []);
  const editorRef = useRef(null);
  const [ready, setReady] = useState(false);

  const { data } = useVerifyTokenAndGetConfig(
    process.env.GATSBY_SHIRTY_APP_DEMO_TOKEN
  );

  useEffect(() => {
    if (!data || ready) {
      return;
    }

    async function setup() {
      try {
        const { configuration } = data;
        await editor.initialize(
          editorRef.current,
          process.env.GATSBY_SHIRTY_APP_DEMO_TOKEN,
          configuration,
          {
            width: window.innerWidth,
            height: window.innerHeight,
            style: "border: none; width: 100%;",
          }
        );
        await editor.selectProduct(configuration.defaultProduct);

        if (configuration.templates.length > 0) {
          const templateURL = `${process.env.GATSBY_SHIRTY_APP_URL}${_.sample(configuration.templates).url}`;
          const template = await fetch(templateURL).then((v) => v.json());

          await editor.addTemplate(template);
        }
        setReady(true);
      } catch (e) {
        console.error(e);
      }
    }

    setup();
  }, [editor, editorRef, data, setReady, ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    const onResize = () => editor.resize(window.innerWidth, window.innerHeight);

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [editor, ready]);

  return <div ref={editorRef} className="relative flex w-full" />;
};
