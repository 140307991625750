const React = require("react");
const { PageWrapper } = require("./src/context/PageWrapper");
const AOS = require("aos");
require("./src/styles/main.scss");

export const wrapPageElement = (props) => {
  setTimeout(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  return <PageWrapper {...props} />;
};
